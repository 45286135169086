<template>
  <v-sheet class="about">
    <div class="ma-4 text-caption"><a @click="$router.back()">戻る</a></div>
    
    <v-card class="ma-4">
      <v-card-title>バージョン</v-card-title>
      <v-card-text>{{ app_ver }}</v-card-text>
    </v-card>

    <v-card class="ma-4">
      <v-card-title>利用しているソフトウェアのライセンスについて</v-card-title>
      <v-card-text>このWebアプリケーションは、以下のライブラリやプログラムを用いて開発、実装しています。</v-card-text>
      <v-card-text>
        <v-card v-for="license in licenses" :key="license.name" class="ma-2">
          <v-card-title>{{license.name}}</v-card-title>
          <v-card-subtitle>作者: {{license.author}}, ライセンス: {{license.licenseType}}</v-card-subtitle>
          <v-card-text>
              <v-banner
                single-line
              >
              <template v-slot:actions>
                <v-btn
                  text
                  color="deep-purple accent-4"
                  :href="license.link.substr(4)"
                  target="_blank"
                >
                  {{license.link.substr(4)}}
                </v-btn>
                <v-btn
                  text
                  color="deep-purple accent-4"
                  :href="`https://raw.githubusercontent.com/${license.link.substring(23, license.link.length - 4)}/master/LICENSE`"
                  target="_blank"
                >
                  ライセンス
                </v-btn>
              </template>
            </v-banner>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
    
  </v-sheet>
</template>
<script>

export default {
  name: 'home',
  components: {
  },
  data() {
    return {
      licenses: [],
      app_ver: require('../../package.json').version,
    }
  },
  async mounted() {
    const licenses = await import('@/assets/licenses.json');
    console.log(licenses, typeof licenses, licenses.length, );
    Object.keys(licenses).forEach((key) => {
      if (licenses[key].name) this.licenses.push(licenses[key]);
    });
    console.log('licenses', this.licenses);
  }
}
</script>
